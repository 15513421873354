const { WebCredentialHandler, credentialHandlerPolyfill } = window;

const MEDIATOR =
  "https://authn.io/mediator?origin=" +
  encodeURIComponent(window.location.origin);

const WALLET_LOCATION = window.location.origin + "/";
const workerUrl = WALLET_LOCATION + "wallet-worker.html";

export const registerWalletWithBrowser = async () => {
  try {
    await credentialHandlerPolyfill.loadOnce(MEDIATOR);
  } catch (e) {
    console.error("Error in loadOnce:", e);
  }

  console.log("Polyfill loaded.");

  console.log("Installing wallet worker handler at:", workerUrl);

  const registration = await WebCredentialHandler.installHandler({
    url: workerUrl,
  });

  await registration.credentialManager.hints.set("test", {
    name: "TestUser",
    enabledTypes: [
      "VerifiablePresentation",
      "VerifiableCredential",
      "AlumniCredential",
    ],
    // enabledTypes: ['VerifiablePresentation']
  });

  console.log("Wallet registered.");
};

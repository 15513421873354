import React from "react";
import MaterialTable from "material-table";
import _ from "lodash";
import { Identicon } from "../Identicon";

import { WalletContentDetailPanel } from "./WalletContentDetailPanel";

export const WalletContentTable = ({ contents }) => {
  return (
    <MaterialTable
      title="Content"
      columns={[
        {
          title: "ID",
          field: "id",
          render: (rowData) => {
            return <Identicon value={rowData.id} />;
          },
        },
        {
          title: "Type",
          field: "type",
          render: (rowData) => {
            const value =
              typeof rowData.type === "string"
                ? rowData.type
                : rowData.type[rowData.type.length - 1];
            return <Identicon value={_.startCase(value)} />;
          },
        },
      ]}
      data={contents}
      detailPanel={(rowData) => {
        let withoutTableData = JSON.parse(JSON.stringify(rowData));
        delete withoutTableData.tableData;
        return <WalletContentDetailPanel row={withoutTableData} />;
      }}
    />
  );
};

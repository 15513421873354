import React from "react";

import { JSONEditorReact } from "../JSONEditorReact";

import { CredentialPreview } from "../CredentialPreview";

export const WalletContentDetailPanel = ({ row }) => {
  if (!row.type.includes("VerifiableCredential")) {
    return (
      <>
        <JSONEditorReact json={row} />
      </>
    );
  }
  if (row.type.includes("VerifiableCredential")) {
    return (
      <>
        <CredentialPreview credential={row} />
      </>
    );
  }
};

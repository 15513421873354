export const remoteMap = {
  "did:key:z6Mki93tJ2rwrDaWTMZcdMShk4xLWf75Fu559dAapKe8pdmX": {
    issuer: "did:key:z6Mki93tJ2rwrDaWTMZcdMShk4xLWf75Fu559dAapKe8pdmX",
    issuerEndpoint: "https://vc.mesur.io/next/credentials/issue",
    presentEndpoint: "https://vc.mesur.io/next/presentations/prove",
    verificationMethod:
      "did:key:z6Mki93tJ2rwrDaWTMZcdMShk4xLWf75Fu559dAapKe8pdmX#z6Mki93tJ2rwrDaWTMZcdMShk4xLWf75Fu559dAapKe8pdmX",
  },

  "did:v1:test:nym:z6MkfG5HTrBXzsAP8AbayNpG3ZaoyM4PCqNPrdWQRSpHDV6J": {
    issuer: "did:v1:test:nym:z6MkfG5HTrBXzsAP8AbayNpG3ZaoyM4PCqNPrdWQRSpHDV6J",
    issuerEndpoint: "https://producer-api.neoflow.ca/v1/credentials/issue",
    verificationMethod:
      "did:v1:test:nym:z6MkfG5HTrBXzsAP8AbayNpG3ZaoyM4PCqNPrdWQRSpHDV6J#z6MkqfvdBsFw4QdGrZrnx7L1EKfY5zh9tT4gumUGsMMEZHY3",
  },
  "did:web:amazon-qa.wallet.spherity.io": {
    issuer: "did:v1:test:nym:z6MkfG5HTrBXzsAP8AbayNpG3ZaoyM4PCqNPrdWQRSpHDV6J",
    issuerEndpoint:
      "https://amazon-qa.api.wallet.spherity.io/api/v1/credential/issue",
    verificationMethod:
      "did:web:amazon-qa.wallet.spherity.io#z6MkpcgzRs3WffbyDs5NPwuEskwfJeTAsJmNBQBjJHHtK8ys",
  },
};

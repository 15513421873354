export const requestByExample = ({ user, organization, type }) => {
  return {
    web: {
      VerifiablePresentation: {
        query: [
          {
            type: "QueryByExample",
            credentialQuery: {
              reason: `${user.name} from  ${organization.name} is requesting credentials.`,
              example: {
                "@context": ["https://www.w3.org/2018/credentials/v1"],
                type,
              },
            },
          },
        ],
        // a 128-bit randomly generated value encoded as a string (use a UUID);
        // it will be digitally signed in the authentication proof
        // that will be attached to the VerifiablePresentation response
        challenge: "99612b24-63d9-11ea-b99f-4f66f3e4f81a",
        // the domain that must be digitally signed in the authentication
        // proof that will be attached to the VerifiablePresentation
        // response, identifying the recipient
        domain: window.location.hostname,
      },
    },
  };
};

import React from "react";

import { Base } from "../../templates/Base";

import Button from "@material-ui/core/Button";

// import { Identicon } from "../../atoms/Identicon";
import Typography from "@material-ui/core/Typography";

import { Menu } from "../../atoms/Menu";

import { CredentialsTable } from "../../atoms/CredentialsTable";

export const StoreCredential = ({
  flow,
  user,
  users,
  selectUser,
  // handleCancel,
  actors,
  handleStoreCredentials,
  addContentToWallet,
}) => {
  const doStoreCredential = async () => {
    const content = [
      ...flow.vp.verifiableCredential.filter((row) => {
        return row.tableData.checked;
      }),
    ].map((row) => {
      const r = { ...row };
      delete r.tableData;
      return r;
    });
    addContentToWallet({
      holder: user.id,
      content,
    });
    handleStoreCredentials(flow.vp);
  };

  return (
    <Base
      title={"Credential offer"}
      appBarEndItems={
        <>
          {user.name}{" "}
          <Menu user={{ ...user }} users={users} selectUser={selectUser} />
          <>
            <Button
              onClick={doStoreCredential}
              style={{ marginLeft: "8px" }}
              variant={"contained"}
              color={"secondary"}
            >
              Save
            </Button>
          </>
        </>
      }
    >
      <Typography variant={"h6"} gutterBottom>
        <strong>{new URL(flow.credentialRequestOrigin).hostname} </strong>is
        offering credentials.
      </Typography>
      <CredentialsTable contents={flow.vp.verifiableCredential} />
    </Base>
  );
};

import { withRouter } from "react-router";
import { compose } from "redux";

import { transmute } from "../../store/transmute";
import { SendCredentials } from "./SendCredentials";

export const SendCredentialsPage = compose(
  withRouter,
  transmute.container
)(SendCredentials);

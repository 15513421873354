import React from "react";

import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Base } from "../../templates/Base";
import { AvatarMenuIcon } from "../../atoms/AvatarMenuIcon";

import { AutocompleteMultiple } from "../../atoms/AutocompleteMultiple";

import { Menu } from "../../atoms/Menu";
import { CredentialPreview } from "../../atoms/CredentialPreview";

export const ReceiveCredentials = ({
  transmute,
  addContentToWallet,
  ...props
}) => {
  const { users, organizations, actors } = transmute;
  const {
    match: {
      params: { did },
    },
  } = props;

  const [authenticatedHolder, setAuthenticatedHolder] = React.useState(false);
  const [credentials, setCredentials] = React.useState([]);
  const [presentaton, setPresentation] = React.useState({
    verifiableCredential: [],
  });

  const [alert, setAlert] = React.useState({
    open: false,
    setOpen: () => {
      setAlert({ open: false });
    },
    severity: "success",
    message: "",
  });

  const organization = organizations.find((org) => {
    return org.id === did;
  });

  const user = users.find((user) => {
    return user.id === did;
  });

  const handleAuthentication = async () => {
    try {
      const type = "DIDAuth";
      // normally this would come from a secure backend.
      const {
        data: { holder },
      } = await navigator.credentials.get({
        web: {
          VerifiablePresentation: {
            query: {
              type: type,
            },
            // a 128-bit randomly generated value encoded as a string (use a UUID);
            // it will be digitally signed in the authentication proof
            // that will be attached to the VerifiablePresentation response
            challenge: "99612b24-63d9-11ea-b99f-4f66f3e4f81a",
            // the domain that must be digitally signed in the authentication
            // proof that will be attached to the VerifiablePresentation
            // response, identifying the recipient
            domain: window.location.hostname,
          },
        },
      });
      setAlert({
        open: true,
        setOpen: () => {
          setAlert({ open: false });
        },
        severity: "success",
        message: `Authenticated as ${holder}`,
      });

      setTimeout(() => {
        setAuthenticatedHolder(holder);
      }, 2 * 1000);
    } catch (e) {
      console.warn(e);
    }
  };

  const getCta = () => {
    if (authenticatedHolder) {
      let authenticatedUser = users.find((u) => {
        return u.id === authenticatedHolder;
      });

      authenticatedUser = authenticatedUser || {
        name: authenticatedHolder,
        brand: { logo: "" },
      };

      return (
        <>
          <>{authenticatedUser.name}</>
          <Menu
            user={{ ...authenticatedUser }}
            users={[]}
            selectUser={() => {}}
          />{" "}
        </>
      );
    }
    return (
      <Button
        variant={"contained"}
        color={"secondary"}
        onClick={handleAuthentication}
      >
        Sign In
      </Button>
    );
  };

  const options = actors[user.id].filter((t) => {
    return t.type.includes("VerifiableCredential");
  });

  const handleChangeRequestedCredentials = (event, data) => {
    // This should be fixed at sourcce...
    let sanitized = JSON.parse(JSON.stringify(data));
    sanitized = sanitized.map((c) => {
      delete c.tableData;
      return c;
    });
    setCredentials(sanitized);
  };

  const receiveCredentials = async () => {
    const vp = {
      "@context": [
        "https://www.w3.org/2018/credentials/v1",
        "https://www.w3.org/2018/credentials/examples/v1",
      ],
      type: "VerifiablePresentation",
      verifiableCredential: [...credentials],
    };

    const webCredentialWrapper = new global.WebCredential(vp.type, vp);
    // Use Credential Handler API to store
    const result = await navigator.credentials.store(webCredentialWrapper);
    console.log("chapi store completed ", result);
    setAlert({
      open: true,
      setOpen: () => {
        setAlert({ open: false });
      },
      severity: "success",
      message: "Presentation content stored in wallet.",
    });
    setTimeout(() => {
      setPresentation(vp);
    }, 2 * 1000);
  };

  return (
    <Base
      alert={alert}
      organization={organization.id}
      title={`${organization.name}`}
      showDrawer={false}
      appBarEndItems={getCta()}
    >
      <>
        <Grid
          style={{ marginTop: "24px" }}
          container
          spacing={4}
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <AvatarMenuIcon
              size={128}
              user={{
                ...user,
                picture: window.location.origin + organization.logo,
                organizationLogoUrl: user.picture,
              }}
            />
          </Grid>
          <Grid>
            <div style={{ textAlign: "center" }}>
              <Typography variant={"h5"}>
                {" "}
                Welcome to {organization.name}
              </Typography>
              <Typography style={{ marginTop: "8px", marginBottom: "32px" }}>
                {authenticatedHolder
                  ? "Request credentials from "
                  : "Please sign in to request credentials from "}{" "}
                {organization.name}.
              </Typography>
            </div>
            {authenticatedHolder && (
              <div style={{ marginBottom: "24px" }}>
                <AutocompleteMultiple
                  label={"Verifiable Credentials"}
                  onChange={handleChangeRequestedCredentials}
                  options={options}
                />

                <div style={{ textAlign: "center", marginTop: "24px" }}>
                  <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={receiveCredentials}
                  >
                    Request Credentials
                  </Button>
                </div>
              </div>
            )}
          </Grid>
          <Grid>
            <div style={{ margin: "10%", background: "#d2d2d2" }}>
              {presentaton.verifiableCredential.length !== 0 &&
                presentaton.verifiableCredential.map((vc) => {
                  return <CredentialPreview credential={vc} key={vc.id} />;
                })}
            </div>
          </Grid>
        </Grid>
      </>
    </Base>
  );
};

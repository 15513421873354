import React from "react";
import { v4 as uuidv4 } from "uuid";
import Button from "@material-ui/core/Button";

import Box from "@material-ui/core/Box";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import StyleIcon from "@material-ui/icons/Style";
import StyleOutlined from "@material-ui/icons/StyleOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";

import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import { Base } from "../../templates/Base";
import { Menu } from "../../atoms/Menu";
import { CredentialPreview } from "../../atoms/CredentialPreview";

import { Autocomplete } from "../../atoms/Autocomplete";
import { credentials } from "../../data/credentials";
import { getIssuedCredential } from "../../services/credentials";
import { issue } from "../../services/issuer";
import _ from "lodash";

import products from "../../data/products.json";

import issuerCredentials from "../../data/issueCredentialsMap.json";

const optionLabel = (option) => {
  if (option === null) {
    return "";
  }
  if (Array.isArray(option.type)) {
    return _.startCase(option.type[option.type.length - 1]);
  }
  return option.name || option.id;
};

const getCredentialsForUser = (user) => {
  const credentialOptions = Object.values(credentials)
    .filter((item) => {
      const lastType = [...item.type].pop();
      return (
        issuerCredentials[user.id] &&
        issuerCredentials[user.id].includes(lastType)
      );
    })
    .map((item) => {
      if (typeof item.issuer === "string") {
        item.issuer = {
          id: user.id,
        };
      } else {
        item.issuer = {
          ...item.issuer,
          id: user.id,
        };
      }
      return item;
    });
  return credentialOptions.length
    ? credentialOptions
    : Object.values(credentials);
};

export const Issue = ({ transmute, selectUser, addContentToWallet }) => {
  const { user, users, actors } = transmute;
  const subjectOptions = [...products, ...users];

  const credentialOptions = getCredentialsForUser(user);
  let credentialForUser = {
    ...credentialOptions[0],
    credentialSubject: {
      ...credentialOptions[0].credentialSubject,
      id: subjectOptions[0].id,
    },
  };

  const [credential, setCredential] = React.useState(credentialForUser);

  const supportsZkp =
    actors[user.id] &&
    actors[user.id].find((k) => {
      return k.type === "Bls12381G2Key2020";
    }) !== undefined;

  const [selectiveDisclosure, setSelectiveDisclosure] = React.useState(
    supportsZkp
  );

  const [alert, setAlert] = React.useState({
    open: false,
    severity: "success",
    message: "",
  });

  const handleUserChange = (user) => {
    selectUser(user);
    const credentialOptions = getCredentialsForUser(user);
    let credentialForUser = {
      ...credentialOptions[0],
      credentialSubject: {
        ...credentialOptions[0].credentialSubject,
        id: user.id,
      },
    };
    if (typeof credentialForUser.issuer === "string") {
      credentialForUser.issuer = {
        id: user.id,
      };
    } else {
      credentialForUser.issuer = {
        ...credentialForUser.issuer,
        id: user.id,
      };
    }

    let supportsZkp =
      actors[user.id] &&
      actors[user.id].find((k) => {
        return k.type === "Bls12381G2Key2020";
      }) !== undefined;
    setSelectiveDisclosure(supportsZkp);
    setCredential(credentialForUser);
  };

  const handleSelectCredential = (event, option) => {
    if (option) {
      let credential = { ...option };
      option.issuer = user.id;
      setCredential({
        ...credential,
        credentialSubject: {
          ...credential.credentialSubject,
          id: user.id,
        },
      });
    }
  };

  const handleIssueCredential = async () => {
    let credentialTemplate = { ...credential };
    let id = uuidv4();
    credentialTemplate.id = `https://example.com/credentials${id}`;
    const vc = await issue(user.id, credentialTemplate, selectiveDisclosure);
    setAlert({
      open: true,
      setOpen: () => {
        setAlert({ open: false });
      },
      severity: "success",
      message: `Issued ${id}`,
    });
    addContentToWallet({ holder: user.id, content: [vc] });
  };

  const getCta = () => {
    return (
      <Button
        variant={"contained"}
        color={"secondary"}
        onClick={handleIssueCredential}
      >
        Issue
      </Button>
    );
  };

  const handleSubjectChange = (event, subject) => {
    setCredential({
      ...credential,
      credentialSubject: {
        ...credential.credentialSubject,
        id: subject.id,
      },
    });
  };

  return (
    <Base
      title={`Issue`}
      alert={alert}
      showDrawer={true}
      appBarEndItems={
        <>
          <>{user.name}</>
          <Menu
            user={{ ...user }}
            users={users}
            selectUser={handleUserChange}
          />
          <div style={{ marginLeft: "16px" }}>{getCta()}</div>
        </>
      }
    >
      <div style={{ marginBottom: "16px", width: "100%" }}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Autocomplete
              label={"Credential"}
              value={credential}
              options={credentialOptions}
              getOptionSelected={(option) =>
                _.startCase(
                  Array.isArray(option.type)
                    ? [...option.type].pop()
                    : option.type
                )
              }
              getOptionLabel={(option) =>
                _.startCase(
                  Array.isArray(option.type)
                    ? [...option.type].pop()
                    : option.type
                )
              }
              onChange={handleSelectCredential}
            />
            <br />
            <Autocomplete
              label={"Subject"}
              value={subjectOptions.find((u) => {
                return u.id === credential.credentialSubject.id;
              })}
              options={subjectOptions}
              getOptionSelected={(option) => option.id}
              getOptionLabel={(option) => option.name || option.id}
              onChange={handleSubjectChange}
              renderOption={(option) => (
                <>
                  <ListItemAvatar>
                    <Badge
                      overlap="circle"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      badgeContent={
                        <Avatar
                          style={{
                            width: "16px",
                            height: "16px",
                          }}
                          alt="Organizatiton Logo"
                          src={option.brand.image}
                        />
                      }
                    >
                      <Avatar
                        style={{
                          width: "32px",
                          height: "32px",
                        }}
                        alt={`Avatar `}
                        src={option.contactPoint.image}
                      />
                    </Badge>
                  </ListItemAvatar>
                  <ListItemText primary={optionLabel(option)} />
                </>
              )}
              startAdornment={
                <InputAdornment position="start">
                  <>
                    <Badge
                      overlap="circle"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      badgeContent={
                        <Avatar
                          style={{
                            width: "16px",
                            height: "16px",
                          }}
                          alt="Organizatiton Logo"
                          src={
                            subjectOptions.find((u) => {
                              return u.id === credential.credentialSubject.id;
                            }).brand.image
                          }
                        />
                      }
                    >
                      <Avatar
                        style={{
                          width: "32px",
                          height: "32px",
                        }}
                        alt={`Avatar `}
                        src={
                          subjectOptions.find((u) => {
                            return u.id === credential.credentialSubject.id;
                          }).contactPoint.image
                        }
                      />
                    </Badge>
                  </>
                </InputAdornment>
              }
            />
          </Box>
          {supportsZkp && (
            <Box p={1}>
              <FormControlLabel
                style={{ marginLeft: "8px" }}
                onChange={() => {
                  setSelectiveDisclosure(!selectiveDisclosure);
                }}
                control={
                  <Checkbox
                    icon={<StyleOutlined />}
                    checkedIcon={<StyleIcon />}
                    checked={selectiveDisclosure}
                  />
                }
                label="ZKP"
              />
            </Box>
          )}
        </Box>
      </div>

      <CredentialPreview credential={credential} />
    </Base>
  );
};

import React from "react";
import { Switch, Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { HomePage } from "./home";

import { ChapiGet } from "./chapi-get";

import { ChapiStore } from "./chapi-store";
import { SendCredentialsPage } from "./send-credentials";
import { ReceiveCredentialsPage } from "./receive-credentials";
import { OrganizationsPage } from "./organizations";
import { IssuePage } from "./issue";
import { PreviewPage } from "./preview";

import { registerWalletWithBrowser } from "../chapi/register";

import history from "../store/history";

export const Routes = () => {
  React.useEffect(() => {
    (async () => {
      await registerWalletWithBrowser();
    })();
  }, []);
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" exact={true}>
          <HomePage />
        </Route>
        <Route path="/issue" exact={true}>
          <IssuePage />
        </Route>
        <Route path="/organizations" exact={true}>
          <OrganizationsPage />
        </Route>
        <Route path="/chapi/wallet/get" exact={true}>
          <ChapiGet />
        </Route>
        <Route path="/chapi/wallet/store" exact={true}>
          <ChapiStore />
        </Route>
        <Route path="/:did/receive-credentials">
          <ReceiveCredentialsPage />
        </Route>
        <Route path="/:did/send-credentials">
          <SendCredentialsPage />
        </Route>

        <Route path="/preview" exact={true}>
          <PreviewPage />
        </Route>
      </Switch>
    </ConnectedRouter>
  );
};

import { remoteMap } from "./remoteMap";
import axios from "axios";

export const getRemoteIssuedCredential = async (id, credential) => {
  const { issuer, issuerEndpoint } = remoteMap[id];
  const payload = credential;
  if (typeof credential.issuer === "string") {
    payload.issuer = payload;
  } else {
    payload.issuer = {
      ...payload,
      id: issuer,
    };
  }
  const res = await axios.post(issuerEndpoint, {
    credential,
  });
  const vc = res.data;
  return vc;
};

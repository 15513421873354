import { actors } from "../data/actors";

import { getIssuedCredential } from "./credentials";
import { getRemoteIssuedCredential } from "./remote";

export const issue = async (id, credentialTemplate, selectiveDisclossure) => {
  if (actors[id]) {
    return getIssuedCredential(id, credentialTemplate, selectiveDisclossure);
  }
  return getRemoteIssuedCredential(id, credentialTemplate);
};

import * as ed25519 from "@transmute/did-key-ed25519";
import { Ed25519Signature2018 } from "@transmute/ed25519-signature-2018";

import { actors } from "../../data/actors";

import { Bls12381G2KeyPair } from "@transmute/did-key-bls12381";
import {
  BbsBlsSignature2020,
  // BbsBlsSignatureProof2020,
  // deriveProof,
} from "@mattrglobal/jsonld-signatures-bbs";

export const getAuthenticationSuite = async (did) => {
  const signingKey = actors[did].find((c) => {
    return c.type === "JsonWebKey2020" || c.type === "Bls12381G2Key2020";
  });
  let suite;
  if (signingKey.type === "JsonWebKey2020") {
    suite = new BbsBlsSignature2020({
      key: await Bls12381G2KeyPair.from(signingKey),
    });
  }

  if (signingKey.type === "Bls12381G2Key2020") {
    suite = new Ed25519Signature2018({
      key: ed25519.Ed25519KeyPair.from(signingKey),
    });
  }

  return suite;
};

import { remoteMap } from "./remoteMap";
import axios from "axios";

export const getRemotePresentation = async (
  holder,
  credentials,
  domain,
  challenge
) => {
  const { presentEndpoint, verificationMethod } = remoteMap[holder];

  const presentation = {
    "@context": ["https://www.w3.org/2018/credentials/v1"],
    type: ["VerifiablePresentation"],
    holder: holder,
    verifiableCredential: credentials,
  };

  if (!presentEndpoint) {
    return presentation;
  }
  const res = await axios.post(presentEndpoint, {
    presentation,
    options: {
      domain: domain,
      challenge: challenge,
      proofPurpose: "authentication",
      verificationMethod,
    },
  });
  return res.data;
};

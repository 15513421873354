import React from "react";


import {TransmuteLoading} from '../../atoms/TransmuteLoading'

import { ConnectedStoreCredential} from '../../organisms/StoreCredential'

const { credentialHandlerPolyfill, WebCredentialHandler } = window;


export const ChapiStore = () => {

  const  [loading, setLoading] = React.useState(true)
  const [flow, setFlow] = React.useState({
    query: {}, 

  })
  const [event, setEvent] = React.useState({})

  React.useEffect(() => {
    async function handleGetEvent() {
      const event = await WebCredentialHandler.receiveCredentialEvent();
      console.log('Wallet processing store() event:', event);
      const vp = event.credential.data
      const {credentialRequestOrigin} = event

      setFlow({
        query: {type: 'StoreCredentials'},
        vp,
        credentialRequestOrigin
      })

      setEvent(event);
  
      setTimeout(()=>{
        setLoading(false)
      }, 1 * 1000)
    
    }
    credentialHandlerPolyfill
      .loadOnce()
      .then(handleGetEvent);
  }, [setLoading])

  if (loading){
    return (
      <TransmuteLoading style={{margin:'auto', display:'block', paddingTop: '35%'}}/>
    );
  }

  if (flow && flow.query.type === 'StoreCredentials'){
    return <ConnectedStoreCredential flow={flow} handleCancel={()=>{
      event.respondWith(new Promise(resolve => {
        return resolve({ dataType: 'Response', data: 'canceled' });
      }))
    }}

    handleStoreCredentials={(vp)=>{
      console.log('credential stored...', vp)
      event.respondWith(new Promise(resolve => {
        return resolve({ dataType: 'Response', data: 'result' });
      }))
    }}
    
    />
  }


  return <h2>ChapiStore</h2>;
}



import React from "react";

// import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
// import Grid from "@material-ui/core/Grid";

import { AvatarMenuIcon } from "../../atoms/AvatarMenuIcon";
import Typography from "@material-ui/core/Typography";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Base } from "../../templates/Base";
import { Menu } from "../../atoms/Menu";

import history from "../../store/history";

export const Organizations = ({ transmute, selectUser }) => {
  const { user, users, organizations } = transmute;
  return (
    <Base
      title={`Organizations`}
      showDrawer={true}
      appBarEndItems={
        <>
          <>{user.name}</>
          <Menu user={{ ...user }} users={users} selectUser={selectUser} />
        </>
      }
    >
      <Grid container spacing={4}>
        {organizations
          // let users require and send to themselves...
          // .filter((item) => {
          //   return item.id !== user.id;
          // })
          // .filter((item) => {
          //   return !item.id.includes("patient");
          // })
          .map((item) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <Paper style={{ padding: "24px" }}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <AvatarMenuIcon
                        size={128}
                        user={{
                          ...item,
                          brand: item.contactPoint,
                          contactPoint: item.brand,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant={"h5"} style={{ padding: "16px" }}>
                        {item.name}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Button
                        style={{ marginTop: "16px", marginRight: "8px" }}
                        onClick={() => {
                          history.push(`/${item.id}/receive-credentials`);
                        }}
                      >
                        Receive
                      </Button>
                      <Button
                        style={{ marginTop: "16px" }}
                        onClick={() => {
                          history.push(`/${item.id}/send-credentials`);
                        }}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            );
          })}
      </Grid>
    </Base>
  );
};

import { withRouter } from "react-router";
import { compose } from "redux";

import { transmute } from "../../store/transmute";
import { Organizations } from "./Organizations";

export const OrganizationsPage = compose(
  withRouter,
  transmute.container
)(Organizations);

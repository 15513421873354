import {
  BbsBlsSignatureProof2020,
  deriveProof,
} from "@mattrglobal/jsonld-signatures-bbs";

import { documentLoader } from "../../services/documentLoader";

export const deriveCredential = async (
  verifiableCredential,
  deriveProofFrame
) => {
  return deriveProof(
    verifiableCredential,
    { ...deriveProofFrame, "@context": verifiableCredential["@context"] },
    {
      suite: new BbsBlsSignatureProof2020(),
      documentLoader,
    }
  );
};

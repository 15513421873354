import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import loading from './transmute-loading-white.gif';

export const TransmuteLoading = ({ message, style, fullPage }) => {
  const loadingStyle = {
    filter: 'brightness(20%) sepia(3) saturate(300%) hue-rotate(-150deg)',
    ...style,
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      style={{
        width: fullPage ? '100%' : 'min-content',
      }}
    >
      <Box
        style={{
          flexDirection: 'column',
          textAlign: 'center',
          marginTop: fullPage ? '20%' : '0%',
        }}
      >
        <img src={loading} alt="loading" style={loadingStyle} />
        <Typography style={{ marginTop: '16px' }}>{message}</Typography>
      </Box>
    </Box>
  );
};

TransmuteLoading.propTypes = {
  message: PropTypes.any,
};
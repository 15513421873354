import stacy from "./contents/stacy.json";
import yuki from "./contents/yuki.json";
import malcolm from "./contents/malcolm.json";
import david from "./contents/david.json";
// import robert from "./contents/robert.json";
// import carlosPatient from "./contents/carlos-patient.json";
import carlosManufacturer from "./contents/carlos-manufacturer.json";
import bls12381DidKey from "./contents/bls12381-did-key.json";
import ed25519DidKey from "./contents/ed25519-did-key.json";

export const actors = {
  [stacy[0].controller]: stacy,
  [carlosManufacturer[0].controller]: carlosManufacturer,
  [yuki[0].controller]: yuki,
  [malcolm[0].controller]: malcolm,
  [david[0].controller]: david,
  [ed25519DidKey[0].controller]: ed25519DidKey, //olga
  [bls12381DidKey[0].controller]: bls12381DidKey, //judith

  // [robert[0].controller]: robert,
  // [carlosPatient[0].controller]: carlosPatient,
  // {
  //   "id": "did:web:did.actor:healthcare:doctor:robert",
  //   "name": "Robert",
  //   "brand": {
  //     "image": "/organizations/doctor.jpg"
  //   },
  //   "contactPoint": {
  //     "image": "/users/robert.jpg"
  //   }
  // },
  // {
  //   "id": "did:web:did.actor:healthcare:patient:carlos",
  //   "name": "Carlos",
  //   "brand": {
  //     "image": "/organizations/doctor.jpg"
  //   },
  //   "contactPoint": {
  //     "image": "/users/carlos.jpg"
  //   }
  // },
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Theme } from "../../components/Theme";
import history from "../../store/history";
import { AppBar } from "../../components/AppBar";
import { AlertMessage } from "../../components/AlertMessage";
import { Drawer } from "../../components/Drawer";

import { LogoWithText } from "../../components/LogoWithText";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export const Base = ({
  title,
  organization,
  children,
  appBarBeginItems,
  appBarEndItems,
  showDrawer,
  alert,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Theme organization={organization || "transmute"}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          title={title}
          showDrawer={showDrawer}
          handleDrawerOpen={handleDrawerOpen}
          onClickLogo={() => history.push("/organizations/")}
          open={open}
          beginItems={appBarBeginItems}
          endItems={appBarEndItems}
        />
        {showDrawer && (
          <Drawer
            logo={<LogoWithText />}
            open={open}
            handleDrawerClose={handleDrawerClose}
          />
        )}
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
      <AlertMessage {...alert} />
    </Theme>
  );
};

import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { AvatarMenuIcon } from "../AvatarMenuIcon";

const MyMenu = ({ user, users, selectUser }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        style={{ cursor: "pointer" }}
        color={"inherit"}
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {user ? <AvatarMenuIcon user={user} /> : <MoreVertIcon />}
      </div>
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {users.map((u) => {
          return (
            <MenuItem
              key={u.id}
              onClick={() => {
                selectUser(u);
                handleClose();
              }}
            >
              <ListItemIcon>
                <AvatarMenuIcon user={u} />
              </ListItemIcon>
              <ListItemText primary={u.name} />
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export { MyMenu as Menu };

import React from "react";

import { Base } from "../../templates/Base";
import { Menu } from "../../atoms/Menu";

import c1 from "./c1.json";
import c2 from "./c2.json";
import c3 from "./c3.json";
import c4 from "./c4.json";

import { CredentialPreview } from "../../atoms/CredentialPreview";

const credentials = [c1, c2, c3, c4];

export const Preview = ({ transmute, selectUser }) => {
  const { user, users } = transmute;
  return (
    <Base
      title={`Preview`}
      showDrawer={true}
      appBarEndItems={
        <>
          <>{user.name}</>
          <Menu user={{ ...user }} users={users} selectUser={selectUser} />
        </>
      }
    >
      {credentials.map((c, i) => {
        return <CredentialPreview credential={c} verified={i % 2 === 0} />;
      })}
    </Base>
  );
};

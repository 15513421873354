import * as bls12381 from "@transmute/did-key-bls12381";
import * as ed25519 from "@transmute/did-key-ed25519";
import { Ed25519Signature2018 } from "@transmute/ed25519-signature-2018";
import { BbsBlsSignature2020 } from "@mattrglobal/jsonld-signatures-bbs";
import { ld as vcjs } from "@transmute/vc.js";
import { documentLoader } from "./documentLoader";
import { actors } from "../data/actors";

import { getRemotePresentation } from "./remote";

export const present = async (holder, credentials, domain, challenge) => {
  if (!actors[holder]) {
    return getRemotePresentation(holder, credentials, domain, challenge);
  }
  const presentation = await vcjs.createPresentation({
    holder: holder,
    verifiableCredential: credentials,
  });

  const signingKey = actors[holder].find((c) => {
    return (
      c.type === "Ed25519VerificationKey2018" ||
      c.type === "JsonWebKey2020" ||
      c.type === "Bls12381G2Key2020"
    );
  });

  let suite;

  if (
    signingKey.type === "Ed25519VerificationKey2018" ||
    (signingKey.type === "JsonWebKey2020" &&
      signingKey.publicKeyJwk.crv === "Ed25519")
  ) {
    suite = new Ed25519Signature2018({
      key: await ed25519.Ed25519KeyPair.from(signingKey),
    });
  }

  if (signingKey.type === "Bls12381G2Key2020") {
    suite = new BbsBlsSignature2020({
      key: await bls12381.Bls12381G2KeyPair.from(signingKey),
    });
    if (
      !presentation["@context"].includes("https://w3id.org/security/bbs/v1")
    ) {
      presentation["@context"].push("https://w3id.org/security/bbs/v1");
    }
  }

  if (!suite) {
    throw new Error("could not find signingKey");
  }

  const vp = await vcjs.signPresentation({
    presentation,
    domain: domain,
    challenge: challenge,
    suite,
    documentLoader,
  });
  return vp;
};

import React from "react";

import { Base } from "../../templates/Base";

import { Menu } from "../../atoms/Menu";

import { WalletContentTable } from "../../atoms/WalletContentTable";

export const Home = ({ transmute, selectUser }) => {
  const { user, users, actors } = transmute;

  const contents = actors[user.id];

  return (
    <Base
      title="Wallet"
      showDrawer={true}
      appBarEndItems={
        <>
          <>{user.name}</>
          <Menu user={{ ...user }} users={users} selectUser={selectUser} />
        </>
      }
    >
      <WalletContentTable contents={contents} />
    </Base>
  );
};

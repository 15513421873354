import React from "react";

import { Base } from "../../templates/Base";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

// import { Identicon } from "../../atoms/Identicon";
import { Menu } from "../../atoms/Menu";

import { PresentationsTable } from "../../atoms/PresentationsTable";
import { present } from "../../services/holder";
export const PresentCredential = ({
  flow,
  user,
  users,
  selectUser,
  handleCancel,
  actors,
  handlePresentation,
}) => {
  const contents = actors[user.id].filter((walletContent) => {
    const lastType = [...walletContent.type].pop();
    if (
      flow.query[0].credentialQuery.example.type &&
      flow.query[0].credentialQuery.example.type.length
    ) {
      return flow.query[0].credentialQuery.example.type.includes(lastType);
    }
    return true;
  });

  const doPresentation = async () => {
    console.log("doPresentation...");
    const contentsWithoutMutation = contents
      .filter((row) => {
        return row.tableData.checked;
      })
      .map((c) => {
        let c1 = JSON.parse(JSON.stringify(c));
        delete c1.tableData;
        return c1;
      });

    const vp = await present(
      user.id,
      contentsWithoutMutation,
      flow.domain,
      flow.challenge
    );
    console.log("signed presentation: ", vp);
    handlePresentation(vp);
  };

  return (
    <Base
      title={"Presentation request"}
      appBarEndItems={
        <>
          {user.name}{" "}
          <Menu user={{ ...user }} users={users} selectUser={selectUser} />
          <>
            <Button
              style={{ marginLeft: "8px" }}
              onClick={doPresentation}
              variant={"contained"}
              color={"secondary"}
            >
              Present
            </Button>
          </>
        </>
      }
    >
      <Typography variant={"h6"} gutterBottom>
        {flow.query[0].credentialQuery.reason}
      </Typography>
      <PresentationsTable contents={contents} />
    </Base>
  );
};

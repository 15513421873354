
import { transmute } from '../../store/transmute'
import { StoreCredential } from './StoreCredential'

const Container = ({flow, transmute, selectUser, handleCancel, handleStoreCredentials, addContentToWallet})=>{
    const {user, users, actors } = transmute;
    return <StoreCredential 
    flow={flow} 
    user={user} 
    users={users} 
    selectUser={selectUser} 
    handleCancel={handleCancel} 
    handleStoreCredentials={handleStoreCredentials} 
    actors={actors}
    addContentToWallet={addContentToWallet}
    />
}

export const ConnectedStoreCredential = transmute.container(Container)

import { transmute } from "../../store/transmute";
import { PresentDerivedCredential } from "./PresentDerivedCredential";

const Container = ({
  flow,
  transmute,
  selectUser,
  handleCancel,
  handlePresentation,
}) => {
  const { user, users, actors } = transmute;
  return (
    <PresentDerivedCredential
      flow={flow}
      user={user}
      users={users}
      selectUser={selectUser}
      handleCancel={handleCancel}
      handlePresentation={handlePresentation}
      actors={actors}
    />
  );
};

export const ConnectedPresentDerivedCredential = transmute.container(Container);

import React from "react";

import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import { Base } from "../../templates/Base";
import { AvatarMenuIcon } from "../../atoms/AvatarMenuIcon";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import StyleIcon from "@material-ui/icons/Style";
import StyleOutlined from "@material-ui/icons/StyleOutlined";

import { requestByFrame } from "./requestByFrame";
import { requestByExample } from "./requestByExample";
import _ from "lodash";

import { CredentialPreview } from "../../atoms/CredentialPreview";

import userToRequestedCredentialTypes from "../../data/sendMap.json";

export const SendCredentials = ({
  transmute,
  addContentToWallet,
  selectUser,
  ...props
}) => {
  const {
    // user,
    users,
    organizations,
  } = transmute;

  const {
    match: {
      params: { did },
    },
  } = props;

  const receivingOrganization = organizations.find((org) => {
    return org.id === did;
  });

  const receivingUser = users.find((user) => {
    return user.id === did;
  });

  const [selectiveDisclosure, setSelectiveDisclosure] = React.useState(false);

  const sendCredential = async () => {
    // normally this would come from a secure backend.
    let query = requestByExample({
      user: receivingUser,
      organization: receivingOrganization,
      type: userToRequestedCredentialTypes[receivingUser.id],
    });

    if (selectiveDisclosure) {
      query = requestByFrame({
        user: receivingUser,
        organization: receivingOrganization,
        frame: {
          "@context": [
            "https://www.w3.org/2018/credentials/v1",
            "https://w3id.org/security/bbs/v1",
          ],
          type: ["VerifiableCredential", "VaccinationCertificate"],
          credentialSubject: {
            "@explicit": true,
            id: {},
          },
        },
      });
    }

    const result = await navigator.credentials.get(query);

    if (!result) {
      return;
    }
    const vp = result.data;
    console.log("store content in recipient wallet: ", vp);
    addContentToWallet({ holder: did, content: vp.verifiableCredential });
    setAlert({
      open: true,
      setOpen: () => {
        setAlert({ open: false });
      },
      severity: "success",
      message: "Presentation received.",
    });
    setPresentation(vp);
  };

  const [alert, setAlert] = React.useState({
    open: false,
    setOpen: () => {
      setAlert({ open: false });
    },
    severity: "success",
    message: "",
  });

  const [presentaton, setPresentation] = React.useState({
    verifiableCredential: [],
  });

  const supportsZkp = receivingUser.id.includes("manufacturer");

  return (
    <Base
      alert={alert}
      organization={receivingOrganization.id}
      title={`${receivingOrganization.name}`}
      showDrawer={false}
      // appBarEndItems={
      //   <>
      //     <>{user.name}</>
      //     <Menu user={{ ...user }} users={users} selectUser={selectUser} />
      //   </>
      // }
    >
      <>
        <Grid
          style={{ marginTop: "24px" }}
          container
          spacing={4}
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <AvatarMenuIcon
              size={128}
              user={{
                ...receivingUser,
                picture: window.location.origin + receivingOrganization.logo,
                organizationLogoUrl: receivingUser.picture,
              }}
            />
          </Grid>
          <Grid>
            <div style={{ textAlign: "center" }}>
              <Typography variant={"h5"}>
                {" "}
                Welcome to {receivingOrganization.name}
              </Typography>

              <Typography style={{ marginTop: "8px", marginBottom: "14px" }}>
                Please submit the following credentials for {receivingUser.name}{" "}
                to review:
              </Typography>

              <div style={{ marginBottom: "12px" }}>
                {userToRequestedCredentialTypes[receivingUser.id] &&
                  userToRequestedCredentialTypes[receivingUser.id].map(
                    (credentialType) => {
                      return (
                        <Chip
                          key={credentialType}
                          style={{ margin: "8px" }}
                          variant="outlined"
                          color={"primary"}
                          label={_.startCase(credentialType)}
                        />
                      );
                    }
                  )}
              </div>
              <div style={{ marginBottom: "24px" }}>
                <Button
                  color={"secondary"}
                  variant="contained"
                  onClick={sendCredential}
                >
                  Present Credentials
                </Button>
                <>
                  {supportsZkp && (
                    <FormControlLabel
                      style={{ marginLeft: "8px" }}
                      onChange={() => {
                        setSelectiveDisclosure(!selectiveDisclosure);
                      }}
                      control={
                        <Checkbox
                          icon={<StyleOutlined />}
                          checkedIcon={<StyleIcon />}
                          value={selectiveDisclosure}
                        />
                      }
                      label="With Selective Disclosure"
                    />
                  )}
                </>
              </div>

              <div style={{ margin: "10%", background: "#d2d2d2" }}>
                {presentaton.verifiableCredential.length !== 0 &&
                  presentaton.verifiableCredential.map((vc) => {
                    return <CredentialPreview credential={vc} key={vc.id} />;
                  })}
              </div>
            </div>
          </Grid>
        </Grid>
      </>
    </Base>
  );
};

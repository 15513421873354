import React from "react";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";

export const AvatarMenuIcon = ({ user, size }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    smallAvatar: {
      width: size ? size / 2 : 22,
      height: size ? size / 2 : 22,
      border: `2px solid ${theme.palette.background.paper}`,
    },
    largeAvatar: {
      width: size,
      height: size,
      border: `2px solid #000`,
    },
  }));
  const classes = useStyles();

  const getAvatar = () => {
    if (user && user.contactPoint && user.name) {
      return (
        <Avatar
          className={classes.largeAvatar}
          alt={user.name}
          src={user.contactPoint.image}
        />
      );
    }
    if (user && user.name && user.name.length > 0) {
      return <Avatar className={classes.largeAvatar}>{user.name[0]}</Avatar>;
    }
    return <Avatar className={classes.largeAvatar} />;
  };

  let badgeLogo = user.brand.image;

  return (
    <div className={classes.root}>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent={
          <Avatar
            className={classes.smallAvatar}
            alt="Organizatiton Logo"
            src={badgeLogo}
          />
        }
      >
        {getAvatar()}
      </Badge>
    </div>
  );
};

import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CreateIcon from "@material-ui/icons/Create";
import DomainIcon from "@material-ui/icons/Domain";
import WorkIcon from "@material-ui/icons/Work";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import history from "../../store/history";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  logo: {
    width: "100%",
    padding: theme.spacing(1),
  },
}));

const MyDrawer = ({ open, handleDrawerClose, logo }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        {logo && <div className={classes.logo}>{logo}</div>}

        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>

      <Divider />

      <List>
        <ListItem
          button
          onClick={() => {
            history.push(`/issue`);
          }}
        >
          <ListItemIcon>
            <CreateIcon />
          </ListItemIcon>
          <ListItemText primary={"Issue"} />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push(`/`);
          }}
        >
          <ListItemIcon>
            <WorkIcon />
          </ListItemIcon>
          <ListItemText primary={"Wallet"} />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push(`/organizations`);
          }}
        >
          <ListItemIcon>
            <DomainIcon />
          </ListItemIcon>
          <ListItemText primary={"Organizations"} />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            console.log("reset");
            localStorage.clear("persist:root");
            window.location.reload();
          }}
        >
          <ListItemIcon>
            <RotateLeftIcon />
          </ListItemIcon>
          <ListItemText primary={"Reset"} />
        </ListItem>
      </List>
    </Drawer>
  );
};

export { MyDrawer as Drawer };

import React from "react";

import { Base } from "../../templates/Base";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { TransmuteLoading } from "../../atoms/TransmuteLoading";

// import { Identicon } from "../../atoms/Identicon";
import { Menu } from "../../atoms/Menu";

import { PresentationsTable } from "../../atoms/PresentationsTable";

import {
  deriveCredential,
  getAuthenticationSuite,
} from "../../services/credentials";
import { ld as vcjs } from "@transmute/vc.js";
import { documentLoader } from "../../services/documentLoader";

export const PresentDerivedCredential = ({
  flow,
  user,
  users,
  selectUser,
  handleCancel,
  actors,
  handlePresentation,
}) => {
  // do the derivation, and just show what will be presented

  const [loading, setLoading] = React.useState(true);

  const [derivedCredentials, setDerivedCredentials] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      console.log(
        "use flow frame and matching creddentials to contruct table contentss..."
      );
      const contents = actors[user.id].filter((walletContent) => {
        const lastType = JSON.parse(
          JSON.stringify([...walletContent.type])
        ).pop();
        return flow.query[0].credentialQuery.frame.type.includes(lastType);
      });
      const { frame } = flow.query[0].credentialQuery;
      const _derivedCredentials = await Promise.all(
        contents.map((verifiableCredential) => {
          return deriveCredential(verifiableCredential, frame);
        })
      );
      setDerivedCredentials(_derivedCredentials);
      setLoading(false);
    })();
  }, [flow, actors, user, setDerivedCredentials]);

  const doPresentation = async () => {
    console.log("doPresentation");

    const suite = await getAuthenticationSuite(user.id);

    const contentsWithoutMutation = derivedCredentials
      .filter((row) => {
        return row.tableData.checked;
      })
      .map((c) => {
        let c1 = { ...c };

        delete c1.tableData;
        return c1;
      });
    const vp = await vcjs.signPresentation({
      presentation: await vcjs.createPresentation({
        holder: user.id,
        verifiableCredential: contentsWithoutMutation,
      }),
      domain: flow.domain,
      challenge: flow.challenge,
      suite,
      documentLoader,
    });

    handlePresentation(vp);
  };

  return (
    <Base
      title={"Presentation request"}
      appBarEndItems={
        <>
          {user.name}{" "}
          <Menu user={{ ...user }} users={users} selectUser={selectUser} />
          <>
            <Button
              style={{ marginLeft: "8px" }}
              onClick={doPresentation}
              variant={"contained"}
              color={"secondary"}
            >
              Present
            </Button>
          </>
        </>
      }
    >
      {loading ? (
        <>
          <TransmuteLoading
            style={{ margin: "auto", display: "block", paddingTop: "15%" }}
          />
        </>
      ) : (
        <>
          <Typography variant={"h6"} gutterBottom>
            {flow.query[0].credentialQuery.reason}
          </Typography>
          <PresentationsTable contents={derivedCredentials} />
        </>
      )}
    </Base>
  );
};

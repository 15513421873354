import React from 'react';
import { Tooltip, Chip } from '@material-ui/core';
import ID from 'react-identicons';

export const transmuteBrandedBackgroundColors = [
  '#27225b',
  '#2a2d4c',
  '#565a7c',
  '#8286a3',
];

export const transmuteBrandedForegroundColors = [
  '#ff605d',
  '#594aa8',
  '#fcb373',
  '#48caca',
  '#2cb3d9',
];

export const Identicon = ({ value, onClick, palette, bg = '#FFFFFF' }) => {
  return (
    <Tooltip title={value}>
      <Chip
        size="medium"
        variant="outlined"
        icon={
          <span
            style={{ height: '32px', marginLeft: '14px', marginTop: '1px' }}
          >
            <ID
              size={30}
              count={5}
              string={value}
              palette={[palette] || transmuteBrandedForegroundColors}
              bg={bg || transmuteBrandedBackgroundColors}
            />
          </span>
        }
        style={{ maxWidth: '224px' }}
        label={value}
        onClick={onClick}
      />
    </Tooltip>
  );
};
import React from "react";

import { TransmuteLoading } from "../../atoms/TransmuteLoading";

import { ConnectedDIDAuth } from "../../organisms/DIDAuth";
import { ConnectedPresentCredential } from "../../organisms/PresentCredential";
import { ConnectedPresentDerivedCredential } from "../../organisms/PresentDerivedCredential";

const { credentialHandlerPolyfill, WebCredentialHandler } = window;

export const ChapiGet = () => {
  const [loading, setLoading] = React.useState(true);
  const [flow, setFlow] = React.useState({
    query: {},
    domain: "",
    challenge: "",
  });
  const [event, setEvent] = React.useState({});

  React.useEffect(() => {
    async function handleGetEvent() {
      const event = await WebCredentialHandler.receiveCredentialEvent();
      console.log("Wallet processing get() event:", event);
      setEvent(event);
      const { query, domain, challenge } =
        event.credentialRequestOptions.web.VerifiablePresentation;
      const { credentialRequestOrigin } = event;

      setFlow({ query, domain, challenge, credentialRequestOrigin });

      setTimeout(() => {
        setLoading(false);
      }, 1 * 1000);
    }
    credentialHandlerPolyfill.loadOnce().then(handleGetEvent);
  }, [setLoading]);

  if (loading) {
    return (
      <TransmuteLoading
        style={{ margin: "auto", display: "block", paddingTop: "35%" }}
      />
    );
  }

  if (flow && flow.query.type === "DIDAuth") {
    return (
      <ConnectedDIDAuth
        flow={flow}
        handleCancel={() => {
          event.respondWith(
            new Promise((resolve) => {
              return resolve({ dataType: "Response", data: "canceled" });
            })
          );
        }}
        handleAuthenticate={(vp) => {
          event.respondWith(
            Promise.resolve({
              dataType: "VerifiablePresentation",
              data: vp,
            })
          );
        }}
      />
    );
  }

  if (
    flow &&
    Array.isArray(flow.query) &&
    flow.query[0].type === "QueryByExample"
  ) {
    return (
      <ConnectedPresentCredential
        flow={flow}
        handleCancel={() => {
          event.respondWith(
            new Promise((resolve) => {
              return resolve({ dataType: "Response", data: "canceled" });
            })
          );
        }}
        handlePresentation={(vp) => {
          console.log("handlePresentation....", vp);
          event.respondWith(
            Promise.resolve({
              dataType: "VerifiablePresentation",
              data: vp,
            })
          );
        }}
      />
    );
  }

  if (
    flow &&
    Array.isArray(flow.query) &&
    flow.query[0].type === "QueryByFrame"
  ) {
    return (
      <ConnectedPresentDerivedCredential
        flow={flow}
        handleCancel={() => {
          event.respondWith(
            new Promise((resolve) => {
              return resolve({ dataType: "Response", data: "canceled" });
            })
          );
        }}
        handlePresentation={(vp) => {
          event.respondWith(
            Promise.resolve({
              dataType: "VerifiablePresentation",
              data: vp,
            })
          );
        }}
      />
    );
  }

  return <>unsupported</>;
};


import { transmute } from '../../store/transmute'
import { DIDAuth } from './DIDAuth'

const Container = ({flow, transmute, selectUser, handleCancel, handleAuthenticate})=>{
    const {user, users, actors } = transmute;
    return <DIDAuth flow={flow} user={user} users={users} selectUser={selectUser} handleCancel={handleCancel} handleAuthenticate={handleAuthenticate} actors={actors}/>
}

export const ConnectedDIDAuth = transmute.container(Container)

import { contexts } from "./contexts";

import axios from "axios";

import * as ed25519 from "@transmute/did-key-ed25519";
import * as bls12381 from "@transmute/did-key-bls12381";

export const documentLoader = async (iri) => {
  if (contexts[iri]) {
    return {
      documentUrl: iri,
      document: contexts[iri],
    };
  }

  if (iri.includes("did:web:")) {
    let url = `https://did-web.web.app/api/v1/identifiers/${iri}`;
    const resp = await axios.get(url);
    return {
      documentUrl: iri,
      document: resp.data,
    };
  }

  if (iri.startsWith("did:key:z6M")) {
    const { didDocument } = await ed25519.driver.resolve(iri, {
      accept: "application/did+ld+json",
    });
    return {
      documentUrl: iri,
      document: didDocument,
    };
  }

  if (iri.startsWith("did:key:zUC")) {
    const { didDocument } = await bls12381.driver.resolve(iri, {
      accept: "application/did+ld+json",
    });
    return {
      documentUrl: iri,
      document: didDocument,
    };
  }

  if (
    iri.startsWith(
      "https://w3c-ccg.github.io/vc-http-api/fixtures/revocationList.json"
    )
  ) {
    const resp = await axios.get(iri);

    return {
      documentUrl: iri,
      document: resp.data,
    };
  }

  if (iri.startsWith("did:trustbloc")) {
    const url = `https://resolver.sandbox.trustbloc.dev/1.0/identifiers/${iri}`;
    const resp = await axios.get(url);
    return {
      documentUrl: iri,
      document: resp.data.didDocument,
    };
  }

  if (iri.startsWith("https://issuer.sandbox.trustbloc.dev/status/1")) {
    const resp = await axios.get(iri);
    return {
      documentUrl: iri,
      document: resp.data,
    };
  }

  if (iri.includes("did:")) {
    const url = `https://dev.uniresolver.io/1.0/identifiers/${iri}`;
    const resp = await axios.get(url);
    return {
      documentUrl: iri,
      document: resp.data.didDocument,
    };
  }

  try {
    const resp = await axios({
      method: "get",
      url: iri,
      headers: {
        // Authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    });
    return {
      documentUrl: iri,
      document: resp.data,
    };
  } catch (e) {
    console.error(e);
    // error will be thrown
  }

  console.error("Unsupporteed iri " + iri);
  throw new Error("Unsupporteed iri " + iri);
};

import cmtr from "./cmtr.json";
import bol from "./bol.json";
import inv from "./inv.json";
import vax from "./vax.json";
import link from "./link.json";

export const credentials = {
  cmtr,
  bol,
  inv,
  vax,
  link,
};

import React from "react";

import { Base } from "../../templates/Base";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// import { Identicon } from "../../atoms/Identicon";
import { Menu } from "../../atoms/Menu";

import * as ed25519 from "@transmute/did-key-ed25519";
import { Ed25519Signature2018 } from "@transmute/ed25519-signature-2018";
import { ld as vcjs } from "@transmute/vc.js";
import { documentLoader } from "../../services/documentLoader";

import { Bls12381G2KeyPair } from "@transmute/did-key-bls12381";
import {
  BbsBlsSignature2020,
  // BbsBlsSignatureProof2020,
  // deriveProof,
} from "@mattrglobal/jsonld-signatures-bbs";

export const DIDAuth = ({
  flow,
  user,
  users,
  selectUser,
  handleCancel,
  actors,
  handleAuthenticate,
}) => {
  const doAuthentication = async () => {
    const contents = actors[user.id];
    const signingKey = contents.find((c) => {
      return (
        c.type === "Ed25519VerificationKey2018" ||
        c.type === "JsonWebKey2020" ||
        c.type === "Bls12381G2Key2020"
      );
    });
    let suite;

    const presentation = await vcjs.createPresentation({
      holder: user.id,
    });
    if (
      signingKey.type === "Ed25519VerificationKey2018" ||
      (signingKey.type === "JsonWebKey2020" &&
        signingKey.publicKeyJwk.crv === "Ed25519")
    ) {
      suite = new Ed25519Signature2018({
        key: ed25519.Ed25519KeyPair.from(signingKey),
      });
    }
    if (signingKey.type === "Bls12381G2Key2020") {
      suite = new BbsBlsSignature2020({
        key: await Bls12381G2KeyPair.from(signingKey),
      });
      if (
        !presentation["@context"].includes("https://w3id.org/security/bbs/v1")
      ) {
        presentation["@context"].push("https://w3id.org/security/bbs/v1");
      }
    }

    const vp = await vcjs.signPresentation({
      presentation,
      domain: flow.domain,
      challenge: flow.challenge,
      suite,
      documentLoader,
    });
    handleAuthenticate(vp);
  };

  return (
    <Base
      title={"Authentication request"}
      appBarEndItems={
        <>
          {user.name}{" "}
          <Menu user={{ ...user }} users={users} selectUser={selectUser} />
          <Button
            onClick={doAuthentication}
            style={{ marginLeft: "16px" }}
            variant={"contained"}
            color={"secondary"}
          >
            Authenticate
          </Button>
        </>
      }
    >
      <Typography style={{ marginBottom: "32px" }} variant={"h6"}>
        <strong>{new URL(flow.credentialRequestOrigin).hostname} </strong>is
        requesting authentication.
      </Typography>

      <Typography style={{ marginBottom: "32px" }}>
        Select an account to sign in with.
      </Typography>
    </Base>
  );
};

import React from "react";
import PropTypes from "prop-types";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { darken, lighten } from "@material-ui/core/styles/colorManipulator";
import {
  deepOrange,
  teal,
  green,
  blue,
  red,
  grey,
  indigo,
} from "@material-ui/core/colors";

const secondaryColor = "#594aa8";
const organizationBranding = {
  transmute: {
    primaryColor: "#594aa8",
    secondaryColor: "#fcb373",
  },
  "did:web:did.actor:supply-chain:manufacturer:stacy": {
    primaryColor: deepOrange["700"],
    secondaryColor: secondaryColor,
  },
  "did:web:did.actor:supply-chain:carrier:yuki": {
    primaryColor: teal["600"],
    secondaryColor: secondaryColor,
  },
  "did:web:did.actor:supply-chain:broker:malcolm": {
    primaryColor: green["600"],
    secondaryColor: secondaryColor,
  },
  "did:web:did.actor:supply-chain:customs:david": {
    primaryColor: blue["600"],
    secondaryColor: secondaryColor,
  },
  "did:key:zUC7LaHFbYB7eYU4uXPPMGJTj3ZrFFmgheZRwCDhJGmYQHnUD3zgSWYARgji6qLq2o395DveJMg6LkGmVohB2sGQefCcpYxtG84M33oxo2cdAUHvboeJrhf9dbCdkxvdtsLPGh9": {
    primaryColor: red["800"],
    secondaryColor: grey[300],
  },
  "did:key:z6MkhnmWNTe5tUc6JsfhpxPMEX7QMWFQ274qRs4tZFvatd6X": {
    primaryColor: indigo["600"],
    secondaryColor: grey[300],
  },
  "did:key:z6Mki93tJ2rwrDaWTMZcdMShk4xLWf75Fu559dAapKe8pdmX": {
    primaryColor: green["800"],
    secondaryColor: grey[300],
  },
};

export const Theme = ({ children, organization }) => {
  let themeArgs = organizationBranding[organization];
  if (!themeArgs) {
    themeArgs = {
      primaryColor: "#594aa8",
      secondaryColor: "#fcb373",
    };
  }
  const { primaryColor, secondaryColor } = themeArgs;
  const theme = createMuiTheme({
    splashImage: "",
    palette: {
      type: "light",
      primary: {
        light: lighten(primaryColor, 0.07),
        main: primaryColor,
        dark: darken(primaryColor, 0.07),
      },
      secondary: {
        light: lighten(secondaryColor, 0.07),
        main: secondaryColor,
        dark: darken(secondaryColor, 0.07),
      },
    },
    overrides: {
      MuiExpansionPanel: {
        root: {
          "&:before": {
            display: "none",
          },
        },
      },
    },
  });
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

Theme.propTypes = {
  children: PropTypes.any.isRequired,
};

import * as secContexts from "@transmute/security-context";
import * as credentialsContexts from "@transmute/credentials-context";

export const contexts = {
  [secContexts.constants.SECURITY_CONTEXT_V1_URL]: secContexts.contexts.get(
    secContexts.constants.SECURITY_CONTEXT_V1_URL
  ),
  [secContexts.constants.SECURITY_CONTEXT_V2_URL]: secContexts.contexts.get(
    secContexts.constants.SECURITY_CONTEXT_V2_URL
  ),
  [credentialsContexts.constants
    .CREDENTIALS_CONTEXT_V1_URL]: credentialsContexts.contexts.get(
    credentialsContexts.constants.CREDENTIALS_CONTEXT_V1_URL
  ),
  "https://w3c-ccg.github.io/vc-examples/cmtr/examples/v0.2/cmtr-v0.2.jsonld": require("./cmtr-v0.2.json"),
  "https://w3id.org/security/v3-unstable": require("./sec-v3.json"),
  "https://w3id.org/security/bbs/v1": require("./bbs-v1.json"),
  "https://w3id.org/vaccination/v1": require("./vax-v1.json"),
  "https://www.w3.org/ns/did/v1": require("./did-v1.json"),
  "https://w3id.org/citizenship/v1": require("./cit-v1.json"),
  "https://w3id.org/traceability/v1": require("./trace-v1.json"),
  "https://w3id.org/vc-revocation-list-2020/v1": require("./rev-v1.json"),
  "https://w3id.org/did/v0.11": require("./did-v0.11.json"),
  "https://w3id.org/veres-one/v1": require("./did-veres-one.json"),
};

import { withRouter } from "react-router";
import { compose } from "redux";

import { transmute } from "../../store/transmute";
import { ReceiveCredentials } from "./ReceiveCredentials";

export const ReceiveCredentialsPage = compose(
  withRouter,
  transmute.container
)(ReceiveCredentials);

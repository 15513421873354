import React from "react";
import MaterialTable from "material-table";

import { Identicon } from "../Identicon";
import { JSONEditorReact } from "../JSONEditorReact";

export const CredentialsTable = ({ contents }) => {
  return (
    <MaterialTable
      title="Credentials"
      options={{
        search: false,
        showTitle: false,
        paging: false,
        header: true,
        toolbar: false,
        selection: true,
      }}
      columns={[
        {
          title: "Type",
          field: "type",
          render: (rowData) => {
            const value =
              typeof rowData.type === "string"
                ? rowData.type
                : rowData.type[rowData.type.length - 1];
            return <Identicon value={value} />;
          },
        },
      ]}
      data={contents}
      detailPanel={(rowData) => {
        let withoutTableData = { ...rowData };
        delete withoutTableData.tableData;
        return (
          <>
            <JSONEditorReact json={withoutTableData} />
          </>
        );
      }}
    />
  );
};

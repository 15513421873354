import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const ComboBox = ({
  label,
  value,
  startAdornment,
  options,
  onChange,
  getOptionSelected,
  getOptionLabel,
  renderOption,
}) => {
  return (
    <Autocomplete
      fullWidth
      value={value}
      options={options}
      onChange={onChange}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment,
          }}
          label={label || "Select one"}
          variant="outlined"
        />
      )}
      renderOption={renderOption}
    />
  );
};

export { ComboBox as Autocomplete };

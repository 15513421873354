
import { transmute } from '../../store/transmute'
import { PresentCredential } from './PresentCredential'

const Container = ({flow, transmute, selectUser, handleCancel, handlePresentation})=>{
    const {user, users, actors } = transmute;
    return <PresentCredential flow={flow} user={user} users={users} selectUser={selectUser} handleCancel={handleCancel} handlePresentation={handlePresentation} actors={actors}/>
}

export const ConnectedPresentCredential = transmute.container(Container)

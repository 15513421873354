import * as ed25519 from "@transmute/did-key-ed25519";
import { Ed25519Signature2018 } from "@transmute/ed25519-signature-2018";
import { ld as vcjs } from "@transmute/vc.js";
import { documentLoader } from "../../services/documentLoader";

import { actors } from "../../data/actors";

import { Bls12381G2KeyPair } from "@transmute/did-key-bls12381";
import {
  BbsBlsSignature2020,
  // BbsBlsSignatureProof2020,
  // deriveProof,
} from "@mattrglobal/jsonld-signatures-bbs";

export const getIssuedCredential = async (
  issuer,
  credential,
  selectiveDisclosure
) => {
  let credentialTemplate = JSON.parse(JSON.stringify(credential));
  const signingKey = actors[issuer].find((c) => {
    if (selectiveDisclosure) {
      return c.type === "Bls12381G2Key2020";
    }
    return c.controller === issuer;
  });
  let suite;
  if (selectiveDisclosure) {
    suite = new BbsBlsSignature2020({
      key: await Bls12381G2KeyPair.from(signingKey),
    });
    credentialTemplate["@context"].push("https://w3id.org/security/bbs/v1");
  } else {
    suite = new Ed25519Signature2018({
      key: ed25519.Ed25519KeyPair.from(signingKey),
    });
  }

  return vcjs.issue({
    credential: credentialTemplate,
    suite,
    documentLoader,
  });
};

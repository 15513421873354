/* eslint-disable no-use-before-define */
import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import _ from "lodash";
const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

const Tags = ({ label, value, options, onChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        options={options}
        onChange={onChange}
        getOptionLabel={(option) =>
          _.startCase(
            Array.isArray(option.type) ? [...option.type].pop() : option.type
          )
        }
        value={value}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label || "Select multiple"}
          />
        )}
      />
    </div>
  );
};

export { Tags as AutocompleteMultiple };

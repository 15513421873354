import { handleActions } from "redux-actions";

import { actions } from "./actions";
import organizations from "../../data/organizations.json";
import users from "../../data/users.json";
import { actors } from "../../data/actors";

const initialState = {
  user: users[0],
  users,
  organization: organizations[0],
  organizations,
  actors,
};

export const reducer = handleActions(
  {
    [actions.selectOrganization]: (state, { payload }) => {
      return {
        ...state,
        organization: payload,
      };
    },
    [actions.selectUser]: (state, { payload }) => {
      const organization = organizations.find((organization) => {
        return organization.id === payload.id;
      });
      return {
        ...state,
        organization,
        user: payload,
      };
    },
    [actions.addContentToWallet]: (state, { payload }) => {
      const { holder, content } = payload;
      let existing = state.actors[holder] || [];
      return {
        ...state,
        actors: {
          ...state.actors,
          [holder]: Array.from(new Set([...existing, ...content])),
        },
      };
    },
  },

  initialState
);
